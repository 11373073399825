// Preloader.js

import React, { useState, useEffect } from 'react';

function Preloader({ assetsToLoad, onLoaded }) {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedItems = 0;
    const totalItems = assetsToLoad.length;

    const handleItemLoaded = () => {
      loadedItems += 1;
      const percentLoaded = Math.floor((loadedItems / totalItems) * 100);
      setProgress(percentLoaded);
      if (loadedItems === totalItems) {
        setTimeout(() => setIsLoading(false), 500); // wait 0.5s before starting exit animation
        if (onLoaded) onLoaded();
      }
    };

    assetsToLoad.forEach((src) => {
      if (src.endsWith('.jpg') || src.endsWith('.png') || src.endsWith('.gif') ) {
        const img = new Image();
        img.src = src;
        img.onload = handleItemLoaded;
        img.onerror = handleItemLoaded;
      }
      // Add handling logic for other asset types
    });
  }, [assetsToLoad, onLoaded]);

  return (
    <div className={`fixed inset-0 flex items-center justify-center ${!isLoading && 'fade-out'}`}>
      <div className={`absolute inset-x-0 top-0 bottom-1/2 bg-black ${!isLoading && 'top-slide-out'}`}></div>
      <div className={`absolute inset-x-0 bottom-0 top-1/2 bg-black ${!isLoading && 'bottom-slide-out'}`}></div>
      
      <div className={`text-center transition-opacity duration-500 ${!isLoading && 'opacity-0'}`} style={{ zIndex: 10 }}>
        <div className="text-white text-2xl">{progress}%</div>
        <div className="bg-gray-700 w-64 h-2 mt-4 relative overflow-hidden">
          <div className="bg-green-500 h-2 transition-all duration-500" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
}

export default Preloader;