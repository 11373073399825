import React, { useRef, useEffect, useLayoutEffect } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

export default function Footer() {

    const footerRef = useRef();

    useLayoutEffect(() => {

        ScrollTrigger.create({
            start: '+=200px',
            onEnter: function () {
                footerAnimation('forward')
            },
            onLeaveBack: function () {
                footerAnimation('reverse')
            }
          });

          return () => {
            ScrollTrigger.killAll();
          };

    }, [])

    const footerAnimation = (direction) => {
        gsap.to(footerRef.current, {
            backgroundColor: direction === 'forward' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0)',
            duration: 0.4,
            onComplete: () => {},
            ease: 'power2.inOut',
        });
    };

    return (
    <footer ref={footerRef} className="fixed bottom-0 left-0 w-full py-[10px] border-t border-neutral-700/80 z-[5000]">
        <div className="w-full max-w-7xl mx-auto flex flex-row text-xs">
            <div className=" text-neutral-500">
                <div className="ml-2">&copy; 2024 SystemRuhr</div>
            </div>
            <div className="grow text-right pr-2 text-neutral-500">
                <a href="" className="ml-2">Datenschutz</a>
                <a href="" className="ml-2">Impressum</a>

                
                <div className="w-32 ml-auto items-center flex gap-4">
                <div className="uppercase">Sound on</div>
                <svg viewBox="0 0 42 30" xmlns="http://www.w3.org/2000/svg" className="w-[28px] h-[20px] md:w-[42px] md:h-[30px]">
                <rect x="0" y="0" width="2" height="10" fill="#fff">
                    <animate 
                        attributeName="height" 
                        values="5;25;5" 
                        begin="0.2s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                    <animate 
                        attributeName="y" 
                        values="25; 5; 25" // height of svg element minus height of react
                        begin="0.2s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                </rect>
                <rect x="8" y="0" width="2" height="10" fill="#fff">
                    <animate 
                        attributeName="height" 
                        values="10;30;10" 
                        begin="0.4s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                    <animate 
                        attributeName="y" 
                        values="20; 0; 20" // height of svg element minus height of react
                        begin="0.4s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                </rect>
                <rect x="16" y="0" width="2" height="10" fill="#fff">
                    <animate 
                        attributeName="height" 
                        values="10;30;10" 
                        begin="0.6s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                    <animate 
                        attributeName="y" 
                        values="20; 0; 20" // height of svg element minus height of react
                        begin="0.6s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                </rect>
                <rect x="24" y="0" width="2" height="10" fill="#fff">
                    <animate 
                        attributeName="height" 
                        values="15;30;15" 
                        begin="0s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                    <animate 
                        attributeName="y" 
                        values="15; 0; 15" // height of svg element minus height of react
                        begin="0s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                </rect>
                <rect x="32" y="0" width="2" height="10" fill="#fff">
                    <animate 
                        attributeName="height" 
                        values="5;30;5" 
                        begin="1s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                    <animate 
                        attributeName="y" 
                        values="25; 0; 25" // height of svg element minus height of react
                        begin="1s" 
                        dur="0.6s" 
                        repeatCount="indefinite" />
                </rect>
                </svg>
                </div>

            </div>
        </div>
    </footer>  
    )
}