import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import Section from "../../helpers/SectionGenerator"

import { AiOutlineCoffee } from "react-icons/ai";
import { TfiHeadphone } from "react-icons/tfi";
import { BsCodeSquare } from "react-icons/bs";

import { IconContext } from "react-icons";
import { IoLogoReact } from "react-icons/io5";
import { RiTailwindCssFill } from "react-icons/ri";
import { FaCss3 } from "react-icons/fa";
import { FaWordpressSimple } from "react-icons/fa";
import { TbBrandThreejs } from "react-icons/tb";
import { RiJavascriptLine } from "react-icons/ri";
import { RiBlenderLine } from "react-icons/ri";
import { SiAdobecreativecloud } from "react-icons/si";
import { SiVisualstudiocode } from "react-icons/si";

import { ImXing } from "react-icons/im";
import { GrLinkedin } from "react-icons/gr";

import { imgCoolcat } from "../content/images";

// ################################################
// Funfacts
// ################################################

const funfactsArray = [
    { id: 1, icon: <AiOutlineCoffee />, text: 'Brewing coffee' },
    { id: 2, icon: <TfiHeadphone />, text: 'Listening to next song' },
    { id: 3, icon: <BsCodeSquare />, text: 'Writing lines of Code' },
    { id: 5, icon: <RiBlenderLine />, text: 'Edit 3D models' },
    { id: 6, icon: <IoLogoReact />, text: 'Taking some photos' },
    { id: 7, icon: <IoLogoReact />, text: 'Play Games' }
];

function GenerateFunFacts() {

    const [currentFunfactIndex, setcurrentFunfactIndex] = useState(0);
    const [isFunfactAnimating, setisFunfactAnimating] = useState(true);
    const funfactsRefs = useRef([]);
    const funfactsContainer = useRef([]);
    const progressBar = useRef();

    useGSAP((context, contextSafe) => {

        // AnimateIn
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateIn = contextSafe(() => {
          
          const tl = gsap.timeline();

          tl.to(progressBar.current, { width: 0, duration: 1 })

          tl.fromTo(
            funfactsRefs.current[currentFunfactIndex].querySelector('.icon'),
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 1 }
          )
            .fromTo(
              funfactsRefs.current[currentFunfactIndex].querySelector('.text'),
              { opacity: 0, x: -20 },
              { opacity: 1, x: 0, duration: 1 },
              '<'
            )
            .then(() => {
                setisFunfactAnimating(false);
            });
        });
    
        // AnimateOut
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateOut = contextSafe(() => {

          const tl = gsap.timeline();
    
          tl.to(funfactsRefs.current[currentFunfactIndex].querySelector('.icon'), {
                opacity: 0, 
                y: -20, 
                duration: 1
            })
            .to(funfactsRefs.current[currentFunfactIndex].querySelector('.text'), { 
                opacity: 0, 
                x: 20, 
                duration: 1 
            }, '<')
            .then(() => {
                setcurrentFunfactIndex((prevIndex) =>
                    prevIndex === funfactsArray.length - 1 ? 0 : prevIndex + 1
                );
                setisFunfactAnimating(true);
            });
        });
    
        // AnimateOut 
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateOutProgressBar = contextSafe(() => {
            const tl = gsap.timeline();
            tl.to(progressBar.current, { width: "100%", duration: 5 })
        })


        if (isFunfactAnimating) {

          animateIn();

        } else {

            animateOutProgressBar();
            const timer = setTimeout(() => {
                animateOut();
            }, 5000);
            return () => clearTimeout(timer);
        }

      }, {dependencies: [currentFunfactIndex, isFunfactAnimating], scope: funfactsContainer});


    return (
        <>
        <div ref={funfactsContainer} className="h-20 relative">
        { funfactsArray.map((item, index) => (
            <div 
                key={item.id} 
                ref={(el) => (funfactsRefs.current[index] = el)} 
                className={`absolute w-full h-full transition-opacity duration-500 ${index === currentFunfactIndex ? 'opacity-100' : 'opacity-0'}`}>
                <div className="flex items-center">
                    <div className="icon text-[50px] mr-3">{item.icon}</div>
                    <div className="text text-xl font-oxanium">{item.text}</div>
                </div>
            </div>
        ))}
        </div>
        <div className="bg-neutral-500 ">
            <div ref={progressBar} className="h-4 bg-emerald-400" style={{ width: "0%" }}></div>
        </div>
        </>
    )
}

// ################################################
// Skills
// ################################################

const skillsArray = [
    { id: 1, icon: <IoLogoReact />, name: 'React' },
    { id: 2, icon: <RiTailwindCssFill />, name: 'Tailwind' },
    { id: 3, icon: <FaCss3 />, name: 'CSS' },
    { id: 4, icon: <FaWordpressSimple />, name: 'Wordpress' },
    { id: 5, icon: <TbBrandThreejs />, name: 'Three.js' },
    { id: 6, icon: <RiJavascriptLine  />, name: 'JavaScript' },
    { id: 7, icon: <RiBlenderLine />, name: 'Blender' },
    { id: 8, icon: <SiAdobecreativecloud />, name: 'Creative Cloud' },
    { id: 8, icon: <SiVisualstudiocode />, name: 'VS Code' },
]

function GenerateSkills() {
    const [currentSkillIndex, setcurrentSkillIndex] = useState(0);
    const [isSkillAnimating, setisSkillAnimating] = useState(true);
    const skillsRefs = useRef([]);
    const skillsContainer = useRef();

    useGSAP((context, contextSafe) => {

        // AnimateIn
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateIn = contextSafe(() => {
          
          const tl = gsap.timeline();

          tl.fromTo(
            skillsRefs.current[currentSkillIndex].querySelector('.icon'),
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 1 }
          )
            .fromTo(
              skillsRefs.current[currentSkillIndex].querySelector('.text'),
              { opacity: 0, x: -20 },
              { opacity: 1, x: 0, duration: 1 },
              '<'
            )
            .then(() => {
                setisSkillAnimating(false);
            });
        });
    

        // AnimateOut
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateOut = contextSafe(() => {

          const tl = gsap.timeline();
    
          tl.to(skillsRefs.current[currentSkillIndex].querySelector('.icon'), {
                opacity: 0, 
                y: -20, 
                duration: 1
            })
            .to(skillsRefs.current[currentSkillIndex].querySelector('.text'), { 
                opacity: 0, 
                x: 20, 
                duration: 1 
            }, '<')
            .then(() => {

                setcurrentSkillIndex((prevIndex) =>
                    prevIndex === skillsArray.length - 1 ? 0 : prevIndex + 1
                );
                setisSkillAnimating(true); // Animation neu starten
            });
        });
    

        if (isSkillAnimating) {

          animateIn();

        } else {

            const timer = setTimeout(() => {
                animateOut();
            }, 5000);

            return () => clearTimeout(timer);
        }

    }, {dependencies: [currentSkillIndex, isSkillAnimating], scope: skillsContainer});


    
    return (
        <IconContext.Provider value={{ color: "white", className: "text-[100px] lg:text-[140px] mx-auto" }}>
        <div ref={skillsContainer} className="flex-1 h-80 text-center overflow-hidden relative ">
            {skillsArray.map((item, index) => (
                <div 
                ref={(el) => (skillsRefs.current[index] = el)} 
                key={item.id} 
                className={`absolute w-full h-full transition-opacity duration-500 ${index === currentSkillIndex ? 'opacity-100' : 'opacity-0'}`}>                
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <div className="icon opacity-60">{item.icon}</div>
                        <div className="text uppercase font-oxanium pt-4 text-3xl text-emerald-400">{item.name}</div>
                    </div>
                </div>
            ))}
        </div>
        </IconContext.Provider>
    )
}

// ################################################
// Export AboutSection
// ################################################

export default function AboutSection() {

    function IconCreator({ icon, name }) {
        return (
            <div className="h-80 relative">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className="opacity-60">{icon}</div>
                    <div className="uppercase font-oxanium pt-4 text-3xl text-emerald-400">{name}</div>
                </div>
            </div>
        )
    }

    return (
        <Section name="section-about">
        <div className="min-h-screen"></div>
        <div className="min-h-screen bg-gradient-to-b from-transparent to-black/80"></div>
        <div className="bg-black/80 py-12 min-h-screen">
        <div className="w-full max-w-7xl mx-auto px-5">

            <div className="flex gap-10 mb-14 items-center">
                <h1 className="flex-none text-eyecatcher-xl font-oxanium uppercase">About me</h1>
                <div className="grow bg-emerald-400 h-1"></div>
            </div>

            <div className="grid grid-cols-3 gap-8">
            <div className="col-span-3 lg:col-span-2 p-6 lg:p-10 bg-neutral-950/80 shadow-lg relative">
                
             
                <div className="pl-8 py-4 border-l-8 border-emerald-400 uppercase">
                    <div className="font-oxanium text-eyecatcher-md">Dennis Hochhaus</div>
                    Started SystemRuhr in 2009
                </div>
                
                <div className="md:pl-10 py-6 mb-6 text-gray-400 ">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren.
                </div>


                <div className="relative padding-10 bg-emerald-600/10">
                    <div className="w-4 h-4 border-t border-l border-emerald-400 absolute left-0 top-0"></div>
                    <div className="w-4 h-4 border-t border-r border-emerald-400 absolute right-0 top-0"></div>
                    <div className="w-4 h-4 border-b border-l border-emerald-400 absolute left-0 bottom-0"></div>
                    <div className="w-4 h-4 border-b border-r border-emerald-400 absolute right-0 bottom-0"></div>
                    
                    <div className="p-6 lg:p-10 flex">
                    <div className="flex-1 grow pr-6 lg:pr-10">
                        <GenerateFunFacts />
                        <div className="mt-5">
                        <div className="bg-emerald-400 h-2 w-2 mr-2 float-start"></div>
                        <div className="bg-emerald-400 h-2 w-2 mr-2 float-start"></div>
                        <div className="bg-emerald-400 h-2 w-2 mr-2 float-start"></div>
                        </div>
                    </div>
                    <div className="flex-none w-[60px] lg:w-[140px]">
                        {/* <div className=" ml-auto">
                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className="w-[60px] h-[60px] lg:w-[140px] lg:h-[140px]">
                        <circle cx="100" cy="100" r="95" fill="transparent" stroke="#064E3B" strokeWidth="8"/>
                        <line x1="100" y1="100" x2="100" y2="50" stroke="#065f46" strokeWidth="6" strokeLinecap="round">
                            <animateTransform attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" dur="36s"repeatCount="indefinite"/>
                        </line>
                        <line x1="100" y1="100" x2="100" y2="30" stroke="#065f46" strokeWidth="6" strokeLinecap="round">
                            <animateTransform attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" dur="3s" repeatCount="indefinite"/>                            </line>
                        </svg>
                        </div> */}
                    </div>
                    </div>
                </div> 


                <div className="mt-10 grid grid-cols-2 gap-0 lg:gap-10">
                <div className="col-span-2 md:col-span-1">
                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Likes building PCs</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Has broad tech knowledge</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                </div>
                <div className="col-span-2 md:col-span-1">

                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Dives deep into newest technologies</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Hier noch ein Fact</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                </div>
                </div>


            </div>
            <div className="col-span-3 lg:col-span-1 md:grid md:grid-cols-2 gap-x-5 md:gap-x-0 md:gap-y-4">

                    <div className="md:grow col-span-2 md:col-span-1 lg:col-span-2 bg-neutral-950/80">
                        <img src={imgCoolcat} alt="Coole Katze" className="w-full" />
                        {/* Hologram oder so wäre geil */}
                    </div>
                    <div className="md:grow col-span-2 md:col-span-1 lg:col-span-2 md:flex md-flex-col md:items-center bg-neutral-950/80 relative overflow-hidden mt-8 md:mt-0 lg:mt-4">
                        <div className="absolute left-5 top-1/2 -translate-x-1/2 -rotate-90 origin-top uppercase font-oxanium lg:text-2xl opacity-20">I work with</div>
                        <GenerateSkills />
                    </div>

            </div>
            </div>




            <div className="grid grid-cols-6 gap-8 mt-8">

            <div className="col-span-6 lg:col-span-2 p-5 bg-neutral-950/80 grid grid-cols-2 text-center items-center">
                <div className="col-span-1 border-r border-emerald-800 pr-3">
                <div className="font-oxanium font-bold text-eyecatcher-md mb-2 bg-clip-text text-transparent bg-gradient-to-b from-white to-gray-400">136</div>
                    <div className="text-xs uppercase">Projekte realisiert</div>
                </div>
                <div className="col-span-1 text-lg pl-3">
                    <div className="font-oxanium font-bold text-eyecatcher-md mb-2 bg-clip-text text-transparent bg-gradient-to-b from-white to-gray-400">431</div>
                    <div className="text-xs uppercase">glückliche Kunden</div>                    
                </div>
            </div>
            <div className="col-span-6 md:col-span-3 lg:col-span-2 p-5 bg-neutral-950/80">
                <div className="border-b border-emerald-400 pb-2 mb-4">Want to hire me?</div>
                <div className="text-gray-400">Reach out to me on social channels or send a mail to <span className="text-white">hello@systemruhr.de</span></div>
                
            </div>
            <div className="col-span-6 md:col-span-3 lg:col-span-2 p-5 bg-neutral-950/80">
                <div className="border-b border-emerald-400 pb-2 mb-4">Find me on</div>
                <IconContext.Provider value={{ className: "text-[24px] text-neutral-700 hover:text-emerald-400" }}>
                    <a href="" className="text-gray-400 hover:text-white mr-6 link-hover-emerald-400"><ImXing className="inline-block hover-icon mr-1" /> Xing</a>
                    <a href="" className="text-gray-400 hover:text-white mr-6 link-hover-emerald-400"><GrLinkedin className="inline-block hover-icon mr-1" /> LinkedIn</a>
                </IconContext.Provider>
            </div>
            </div>




        </div>
        </div>
        <div className="min-h-screen bg-gradient-to-b from-black/80 to-transparent"></div>
        <div className="min-h-screen"></div>
        </Section>
    )
}