import React, { useEffect, useRef } from "react";
import { useFrame } from '@react-three/fiber'
import { Environment } from '@react-three/drei'
import { Perf } from 'r3f-perf'

import Effects from "./canvas/Effects";
import Animator from './canvas/Animator'
import ResponsiveCamera from './canvas/ResponsiveCamera'

import Ground from './canvas/Ground'
import BackgroundText from './canvas/BackgroundText'
import Sphere from './canvas/Sphere'
import Pillars from './canvas/Pillars'
import Particles from './canvas/Particles'


export default function Experience()
{

    useFrame((state, delta) => { 

    });

    return <>

        {/* <Perf 
            position="bottom-right" 
            style={{ zIndex: 9999 }}
        /> */}

         <color attach="background" args={["#1E1B4B"]} />
        <ResponsiveCamera />
        <Environment preset="city" />

        <fog attach="fog" args={['#191920', 0, 15]} />

        <directionalLight position={ [ 1, 2, 3 ] } intensity={ 1.5 } />
        <ambientLight intensity={ 1.5 } />
        <pointLight position={[0, 10, -5]} intensity={1} color="#fff" />

        
        <Ground />
        {/*
        <Sphere />
        <BackgroundText /> 
        */}
        
        <Animator />
        <Particles />
        <Pillars />
        <Effects />
    </>

}