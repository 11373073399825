import Section from "../../helpers/SectionGenerator"

export default function PrintSection() {
    return (
        <Section name="section-print">
        <div className="flex flex-col grow justify-center">
        <div className="w-full max-w-7xl mx-auto px-5" style={{ backgroundImage: `url("/assets/earth.png")` }}>
            <div className="text-xxxl font-bold uppercase">Idee-Sektion</div>

            
            Ein Zimmer mit mehreren Gegenständen, zu denen dann gescrollt wird per Kamerafahrt on Scroll. 
            Für Webdesign scrollt man auf den Bildschirm des Computers. Sobald der Bildschirm die maximale Bildhöhe erreicht hat, kann man die Leistungen durchscrollen. Matrix-artiger Stil vielleicht? <br />
            Für Hardware liegt im Raum eine CPU oder ein ähnliches Model in 3D als Low Poly Variante für Hardware<br />

            EIne schöne mechanische Tastatur, Mora. 

            Für Print / Corporate Identity irgendwas mit Visitenkarten, Logos und Briefpapier
        </div>
        </div>        
        </Section>
    )
}