import React, { useRef, useEffect, useLayoutEffect } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

import logo from '../../images/logo.svg'

function Logo() {
    return <img src={logo} alt="SystemRuhr" id="logo" className="h-[40px] lg:h-[60ox] mx-left" />;
}

export default function Header() {

    const headerRef = useRef();

    useLayoutEffect(() => {

        ScrollTrigger.create({
            start: '+=200px',
            onEnter: function () {
                headerAnimation('forward')
            },
            onLeaveBack: function () {
                headerAnimation('reverse')
            }
          });

          return () => {
            ScrollTrigger.killAll();
          };

    }, [])

    const headerAnimation = (direction) => {
        gsap.to(headerRef.current, {
            backgroundColor: direction === 'forward' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0)',
            duration: 0.4,
            onComplete: () => {},
            ease: 'power2.inOut',
        });
    };

    return (
    <header ref={headerRef} className="fixed top-0 left-0 w-full py-[10px] h-[60px] border-b border-neutral-700/80 z-[5000]">
        <div className="w-full max-w-7xl mx-auto px-5">
            <div className=""><Logo /></div>
            {/* <div className="align-self-end">Sektion: About / in Transfer</div> */}
        </div>
    </header> 
    )
}