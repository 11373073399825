import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { Box } from '@react-three/drei';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

export default function Animator() {
  
  const boxRef = useRef();

  useEffect(() => {

    const sections = [
      //{ id: '#section-hero', animation: moveBox },
      { id: '#section-web', animation: animationOne },
      { id: '#section-print', animation: animationTwo },
      { id: '#section-clients', animation: animationThree },
      { id: '#section-about', animation: animationFour },
      { id: '#section-contact', animation: animationFive },
    ];

    sections.forEach(({ id, animation }, index) => {
      ScrollTrigger.create({
        markers: false,
        trigger: id,
        start: 'top center',
        end: 'bottom top',
        scrub: false,
        onEnter: function () {
          animation('forward')
        },
        onLeaveBack: function () {
          animation('reverse')
        },
      });

    });

    return () => {
      ScrollTrigger.killAll();
    };

  }, []);


  // Verschiedene Animationen für jede Sektion

  const animationOne = (direction) => {
    gsap.to(boxRef.current.scale, {
      duration: 2,
      x: direction === 'forward' ? 2 : 1,
      y: direction === 'forward' ? 2 : 1,
      z: direction === 'forward' ? 2 : 1,
      onComplete: () => {

      },
      ease: 'power2.inOut',
    });
  };

  const animationTwo = (direction) => {
    gsap.to(boxRef.current.position, {
      duration: 2,
      x: direction === 'forward' ? 2 : 0,
      y: direction === 'forward' ? 1 : 0,
      z: direction === 'forward' ? -1 : 0,
      onComplete: () => {

      },
      ease: 'power2.inOut',
    });
  };

  const animationThree = (direction) => {
    gsap.to(boxRef.current.material.color, {
      duration: 2,
      r: direction === 'forward' ? 0.2 : 1,
      g: direction === 'forward' ? 0.8 : 0.5,
      b: direction === 'forward' ? 0.5 : 0,
      onComplete: () => {

      },
      ease: 'power2.inOut',
    });
  };

  
  const animationFour = (direction) => {
    gsap.to(boxRef.current.rotation, {
      duration: 2,
      y: direction === 'forward' ? Math.PI * 2 : 0,
      onComplete: () => {

      },
      ease: 'power2.inOut',
    });
  };

  const animationFive = (direction) => {
    gsap.to(boxRef.current.rotation, {
      duration: 2,
      x: direction === 'forward' ? Math.PI * 2 : 0,
      z: direction === 'forward' ? Math.PI * 2 : 0,
      onComplete: () => {

      },
      ease: 'power2.inOut',
    });
    gsap.to(boxRef.current.position, {
      duration: 2,
      y: direction === 'forward' ? 2 : 0,
      ease: 'power2.inOut',
    });
  };

  return (
    <Box ref={boxRef} args={[1, 1, 1]} position={[0, 0, 0]} rotation={[0, Math.PI * 0.25, 0]} >
        <meshStandardMaterial color="orange" />
    </Box>
  );
}
