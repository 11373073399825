import React, { useRef, useEffect, useState } from "react";

import * as THREE from 'three';
import { extend, useFrame, useThree } from '@react-three/fiber'
import { Text, Center } from "@react-three/drei";

class NeonShaderMaterial extends THREE.ShaderMaterial {
    constructor() {
        super({
        uniforms: {
            time: { value: 0 },
        },
        vertexShader: `
            uniform float time;
            varying vec2 vUv;

            void main() {
                vUv = uv;
                vec3 pos = position;
                pos.x += sin(pos.y * 10.0 + time) * 0.5;
                pos.z += cos(pos.y * 10.0 + time) * 0.5;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
            }
        `,
        fragmentShader: `
            uniform float time;
            varying vec2 vUv;

            void main() {
                float glow = abs(sin(time * 2.0));
                gl_FragColor = vec4(vec3(glow, 0.5, 1.0), 1.0);
            }
        `,
        });
    }
}
extend({ NeonShaderMaterial });
  


export default function BackgroundText() {

    const { width: w, height: h } = useThree((state) => state.viewport);

    const shaderRef = useRef();
    const textRef = useRef();

    useFrame(({ clock }) => {
        // console.log(clock)
        if (shaderRef.current) {
            shaderRef.current.uniforms.time.value = clock.getElapsedTime();
        }
    });

    return (
        
        <Text 
            ref={textRef}
            font='/fonts/Oxanium-Bold.ttf'
            fontSize={1}
            //maxWidth={100}
            size={w / 9}
            maxWidth={[-w / 5, -h * 2, 3]}
            height={1}
            lineHeight={0.9}
            letterSpacing={0}

            position={[0, 0, 0]}
            anchorX='center'
            anchorY='middle'
            layers={0}
        >SYSTEMRUHR
            <neonShaderMaterial ref={shaderRef} attach='material' />
        </Text>
    );    

  }