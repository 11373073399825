import { Circle, MeshReflectorMaterial, useTexture } from "@react-three/drei";

export default function Ground(props){

    const [floor, normal] = useTexture(['/assets/SurfaceImperfections003_1K_var1.jpg', '/assets/SurfaceImperfections003_1K_Normal.jpg'])

    return (
    <Circle 
        args={[1, 16]} 
        receiveShadow 
        scale={8} 
        rotation-x={-Math.PI / 2} 
        position-y={-0.6}
        {...props}
        >
      <MeshReflectorMaterial 
        color={'#ffffff'}
        envMapIntensity={0}
        blur={[512, 512]}
        mixBlur={1}
        mixStrength={3}
        mixContrast={1}
        resolution={1024}
        mirror={1}
        depthScale={1}
        minDepthThreshold={0.8}
        maxDepthThreshold={1}
        depthToBlurRatioBias={0.45}
        roughness={1}
      />
    </Circle>    
    )
}