import HeroSection from "./interface/HeroSection";
import WebSection from "./interface/WebSection";
import PrintSection from "./interface/PrintSection";
import ContactSection from "./interface/ContactSection";
import AboutSection from "./interface/AboutSection";
import ClientsSection from "./interface/ClientsSection";

export default function Interface() {
    return (
        <>
        <div className="h-screen w-[1px]"></div>
        <div className="relative z-[1000]">
            <div className="flex flex-col gap-0">
                <HeroSection />
                <WebSection />
                <PrintSection />
                <AboutSection />
                <ClientsSection />
                <ContactSection />
            </div>
        </div>
        </>
    );
}