import { useRef, useEffect, useState } from "react";
import { useThree } from '@react-three/fiber'
import { PerspectiveCamera } from "@react-three/drei";

import gsap from 'gsap';
import { useGSAP } from "@gsap/react";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

export default function ResponsiveCamera(props) {

    const { camera, size } = useThree();
    
    // useEffect(() => {

    //   if (size.width < 768) {
    //     camera.position.set(0, 0.01, 6);
    //     camera.fov = 90;
    //   } else {
    //     camera.position.set(0, 0.01, 6);
    //     camera.fov = 50;
    //   }
    //   camera.near = 0.1,
    //   camera.far = 1000,
    //   camera.updateProjectionMatrix();

    // }, [size, camera]);
  

    useGSAP(() => {

      gsap.to(camera.position, {
        x: -2,
        y: 4.5,
        z: 5,
        immediateRender: false,
        scrollTrigger: {
            markers: true,
            trigger: "#section-print",
            start: "top bottom",
            endTrigger: "#section-about",
            end: "bottom top",
            scrub: 0.1,
            name: 'eins',
            onEnter: function () {
              console.log("Camera trigger started")
            },
            onLeave: function () {
              console.log("Camera trigger end")
            },
        }
      });

      camera.updateProjectionMatrix();

      return () => {
        ScrollTrigger.killAll();
      };

    }, []);

    return (
      <PerspectiveCamera makeDefault {...props} />
    )
  
  }