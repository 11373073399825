import Section from "../../helpers/SectionGenerator"

export default function HeroSection() {
    return (
        <Section name="section-hero">
        <div className="flex-1 h-full grid grid-rows-4">
        <div className="row-span-2 flex flex-col justify-center">

            {/* <div className="w-full max-w-7xl mx-auto px-5 ">
                Willkommen bei SystemRuhr
                <div className="text-orange-600">
                    Mein Name ist Dennis Hochhaus und ich erstelle atemberaubende Webseiten.
                </div>
                <h1 className="text-eyecatcher-xl font-oxanium uppercase">Fluid Motion Design</h1> 
            </div> */}

        </div>
        <div className="row-span-1"></div>
        <div className="row-span-1 flex flex-col justify-center">

            <div className="inline-block relative mx-auto p-5 text-center">
                <div className="scrolldown mx-auto mb-5"></div>
                <div className="font-oxanium uppercase">Scroll to explore</div>
            </div>
            
        </div>
        </div>
        </Section>
    )
}