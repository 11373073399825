import { Bloom, DepthOfField, EffectComposer, HueSaturation, SMAA, Vignette } from '@react-three/postprocessing'
import { BlurPass, Resizer, KernelSize, Resolution } from 'postprocessing'

  export default function Effects() {
    return (
      <EffectComposer multisampling={8}>
        {/* <SMAA />
         <Bloom
          mipmapBlur
          luminanceThreshold={0.9}
          levels={9}
          intensity={0.15}
          radius={0.9}
          luminanceSmoothing={0}
        />
        <Bloom mipmapBlur radius={0.5} luminanceThreshold={0.3} levels={4} intensity={0.2} /> */}
        <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
        <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />        
        <Vignette offset={0.5} darkness={0.7} />
      </EffectComposer>
    )
  }
