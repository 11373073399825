import Section from "../../helpers/SectionGenerator"

import { imgCoolcat } from "../content/images";

export default function ClientsSection() {
    return (
        <Section name="section-clients">
        <div className="bg-black/80 py-12 min-h-screen relative">
        <img src={imgCoolcat} alt="Coole Katze" className="absolute top-0 left-0 w-1/6" />
        <img src={imgCoolcat} alt="Coole Katze" className="absolute top-0 right-0 w-1/6" />
        <img src={imgCoolcat} alt="Coole Katze" className="absolute top-0 left-0 w-1/6" />
        <img src={imgCoolcat} alt="Coole Katze" className="absolute top-0 left-0 w-1/6" />

        <div className="w-full max-w-7xl mx-auto px-5 text-center">
            <h1 className="text-eyecatcher-xl font-oxanium uppercase">Was Kunden sagen</h1> 
        </div>
        </div>
        </Section>
    )
}