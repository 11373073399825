import Section from "../../helpers/SectionGenerator"
import { motion, useScroll, useAnimate, useInView } from "framer-motion"

// ###########################################
// WebServices
// ###########################################

const webServicesArray = [
    {
        name: 'UX-Design', 
        desc: 'Durch klare Strukturen, intuitive Bedienung und ästhetisches Design schaffe ich digitale Erlebnisse, die echte Emotionen wecken und funktional überzeugen.', 
        icon: <><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path fill="currentColor" d="M9 17.41V27h9.59l-2-2H11v-5.59l-2-2z"/><path fill="currentColor" d="M34.87 32.29L32 29.38V32H4v-4.15h2v-1.6H4V19.6h2V18H4v-6.4h2V10H4V4.41l15.94 15.85v-2.82L3.71 1.29A1 1 0 0 0 2 2v31a1 1 0 0 0 1 1h31.16a1 1 0 0 0 .71-1.71Z"/><path fill="currentColor" d="M24 30h4a2 2 0 0 0 2-2V8.7l-2.3-4.23a2 2 0 0 0-1.76-1a2 2 0 0 0-1.76 1.08L22 8.72V28a2 2 0 0 0 2 2Zm0-20.8l1.94-3.77L28 9.21V24h-4Zm0 16.43h4v2.44h-4Z"/><path fill="none" d="M0 0h36v36H0z"/></svg></>
    },
    {
        name: 'Frontend Developement', 
        desc: 'Mit Fokus auf responsives Design, Performance und Benutzerfreundlichkeit entwickle ich ansprechende, interaktive Weblösungen, die auf jedem Gerät optimal funktionieren.', 
        icon: <><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path fill="currentColor" d="M9.6 22.88V10.6h14.8L25.98 9H8v13.88h1.6z"/><path fill="currentColor" d="M6 7h24v16h2V6.5A1.5 1.5 0 0 0 30.5 5h-25A1.5 1.5 0 0 0 4 6.5V23h2Z"/><path fill="currentColor" d="M1 25v3.4A2.6 2.6 0 0 0 3.6 31h28.74a2.6 2.6 0 0 0 2.6-2.6V25Zm32 3.4a.6.6 0 0 1-.6.6H3.56a.6.6 0 0 1-.6-.6v-1.87h9.95a1.64 1.64 0 0 0 1.5 1h7.13a1.64 1.64 0 0 0 1.5-1H33Z"/><path fill="none" d="M0 0h36v36H0z"/></svg></>
    },
    {
        name: 'Content-Management', 
        desc: 'Maßgeschneiderte Webseiten, einfach zu pflegen und flexibel erweiterbar. Ich erstelle benutzerfreundliche Lösungen, die Inhalt und Design perfekt vereinen.', 
        icon: <><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path fill="currentColor" d="M24.23 11.71a39.14 39.14 0 0 0-4.57-3.92a22.86 22.86 0 0 1 3.48-1.72c.32-.12.62-.21.92-.3a2.28 2.28 0 0 0 3.81-.46a3.31 3.31 0 0 1 1.92.84c1.19 1.19 1.22 3.59.1 6.58c.49.65.94 1.31 1.35 2c.17-.4.35-.79.49-1.18c1.47-3.85 1.28-7-.53-8.78a5.29 5.29 0 0 0-3.33-1.44a2.29 2.29 0 0 0-4.31.54c-.37.11-.74.22-1.13.37a25.79 25.79 0 0 0-4.57 2.35a26.21 26.21 0 0 0-4.58-2.39c-3.85-1.46-7-1.28-8.77.53c-1.66 1.67-1.93 4.44-.83 7.86a2.28 2.28 0 0 0 1.59 3.67c.32.61.67 1.22 1.06 1.82A25.54 25.54 0 0 0 4 22.66c-1.47 3.84-1.28 7 .53 8.77a5.63 5.63 0 0 0 4.12 1.51a13.34 13.34 0 0 0 4.65-1a26.21 26.21 0 0 0 4.58-2.35A25.79 25.79 0 0 0 22.43 32a14.16 14.16 0 0 0 3.65.9a2.3 2.3 0 0 0 4.38-.9a4.55 4.55 0 0 0 .74-.57c1.81-1.81 2-4.93.53-8.77a32.68 32.68 0 0 0-7.5-10.95ZM12.57 30.09c-3 1.15-5.45 1.13-6.65-.08s-1.23-3.62-.07-6.64a22.77 22.77 0 0 1 1.71-3.48a40.19 40.19 0 0 0 3.92 4.56c.43.43.87.85 1.31 1.25q.9-.46 1.83-1.05c-.58-.52-1.16-1-1.72-1.61a34 34 0 0 1-5.74-7.47a2.29 2.29 0 0 0-1.66-3.88c-.75-2.5-.62-4.49.43-5.54a3.72 3.72 0 0 1 2.72-.92a11.4 11.4 0 0 1 3.93.84a22.86 22.86 0 0 1 3.48 1.72a39.14 39.14 0 0 0-4.57 3.92c-.44.44-.87.9-1.29 1.36a20.27 20.27 0 0 0 1 1.85c.54-.61 1.09-1.21 1.68-1.8a36.33 36.33 0 0 1 5-4.17a36.88 36.88 0 0 1 4.95 4.17a36.26 36.26 0 0 1 4.17 5a37 37 0 0 1-4.17 5a30.68 30.68 0 0 1-10.26 6.97ZM29.79 30l-.16.13a2.27 2.27 0 0 0-3.5.72a12.57 12.57 0 0 1-3-.77a22 22 0 0 1-3.48-1.72a39.14 39.14 0 0 0 4.57-3.92a38.26 38.26 0 0 0 3.92-4.56a22.88 22.88 0 0 1 1.72 3.48C31 26.39 31 28.81 29.79 30Z" /><circle cx="17.99" cy="18.07" r="3.3" fill="currentColor" transform="rotate(-9.22 17.955 18.05)"/><path fill="none" d="M0 0h36v36H0z"/></svg></>
    },
    {
        name: 'Optimierung & SEO', 
        desc: 'Gezielte Strategien sorgen für eine bessere Sichtbarkeit, schnellere Ladezeiten und eine benutzerfreundliche Struktur, damit Ihre Webseite im Wettbewerb erfolgreich rankt.', 
        icon: <><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path fill="currentColor" d="M31.43 27.28L23 14.84V4h1a1 1 0 0 0 0-2H12a1 1 0 0 0 0 2h1v10.84L4.51 27.36A4.29 4.29 0 0 0 5 32.8A4.38 4.38 0 0 0 8.15 34H28a4.24 4.24 0 0 0 3.42-6.72ZM29.85 31a2.62 2.62 0 0 1-2 1H8a2.2 2.2 0 0 1-2.06-1.41a2.68 2.68 0 0 1 .29-2.17l3-4.44h14l-1.31-2H10.57L15 15.46V4h6v11.46l8.84 13.05a2.23 2.23 0 0 1 .01 2.49Z"/><path fill="none" d="M0 0h36v36H0z"/></svg></>
    },
    {
        name: 'Konfiguration & Einrichtung', 
        desc: 'Ich übernehme die komplette Website-Konfiguration: Vom Hosting über die Domain-Einrichtung bis hin zur sicheren Installation, damit Ihre Website schnell und zuverlässig läuft.', 
        icon: <><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path fill="currentColor" d="M34 23.63L32 23a7.06 7.06 0 0 0-.58-1.41l1-1.86a.37.37 0 0 0-.07-.44l-1.45-1.43a.37.37 0 0 0-.44-.07l-1.85 1a7 7 0 0 0-1.43-.61l-.61-2a.37.37 0 0 0-.36-.25h-2a.37.37 0 0 0-.35.26l-.61 2a7 7 0 0 0-1.44.61l-1.81-1a.37.37 0 0 0-.44.07L18 19.31a.37.37 0 0 0-.07.44l1 1.82a7 7 0 0 0-.58 1.43l-2 .61a.37.37 0 0 0-.26.35v2a.37.37 0 0 0 .26.35l2 .61a7 7 0 0 0 .65 1.45l-1 1.9a.37.37 0 0 0 .07.44l1.45 1.45a.37.37 0 0 0 .44.07l1.87-1a7.06 7.06 0 0 0 1.39.57l.61 2a.37.37 0 0 0 .35.26h2a.37.37 0 0 0 .35-.26l.61-2a7 7 0 0 0 1.38-.57l1.89 1a.37.37 0 0 0 .44-.07l1.45-1.45a.37.37 0 0 0 .07-.44l-1-1.88a7 7 0 0 0 .58-1.39l2-.61a.37.37 0 0 0 .26-.35V24a.37.37 0 0 0-.21-.37Zm-8.83 4.72A3.33 3.33 0 1 1 28.53 25a3.33 3.33 0 0 1-3.34 3.34Z" /><path fill="currentColor" d="M10.85 27.44a2.29 2.29 0 0 0 1.74-1.68a19.71 19.71 0 0 0 1.89-.6v-1.21a2 2 0 0 1 .09-.55a17.42 17.42 0 0 1-2.17.78a2.28 2.28 0 0 0-4.28.63h-.45a11.93 11.93 0 0 1-2.88-7.27a17.79 17.79 0 0 1 5-4.72a2.23 2.23 0 0 0 2.29.56a18.52 18.52 0 0 0 4.65 5.09a1.93 1.93 0 0 1 .23-.32l.89-.87a16.89 16.89 0 0 1-4.49-4.89a2.27 2.27 0 0 0 .35-1.2v-.22A17.69 17.69 0 0 1 25 11a17.49 17.49 0 0 1-1.15 3.35a1.94 1.94 0 0 1 .31-.05h1.45a19.06 19.06 0 0 0 .9-2.7c.43.19.84.41 1.26.64a11.93 11.93 0 0 1 1 4.63l1-.51a2 2 0 0 1 .92-.23h.08A14 14 0 1 0 16.44 31a1.94 1.94 0 0 1 .12-1.46l.28-.53h-.07a11.91 11.91 0 0 1-5.92-1.57ZM16.76 5a12 12 0 0 1 8.61 3.66c0 .25 0 .51-.08.76a19.21 19.21 0 0 0-12.35.11a2.28 2.28 0 0 0-1.2-.53a17 17 0 0 1-.61-2.53A11.92 11.92 0 0 1 16.76 5Zm-7.1 2.36a18.72 18.72 0 0 0 .49 1.92a2.28 2.28 0 0 0-1.07 1.93s0 .1 0 .15A19.45 19.45 0 0 0 5 14.79a12 12 0 0 1 4.66-7.43Z"/><path fill="none" d="M0 0h36v36H0z"/></svg></>
    },
    {
        name: 'Beutreuung, Pflege & Service', 
        desc: 'Ich sorge für regelmäßige Updates, Sicherheitschecks und Optimierungen, damit Ihre Website stets aktuell, sicher und leistungsfähig bleibt und Ihnen nachhaltigen Erfolg garantiert.', 
        icon: <><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36"><path id="clarityCiCdLine0" fill="currentColor" d="M23.53 19.81a7.45 7.45 0 0 1-1.65-.18a10.48 10.48 0 0 1 .72 2.13h.93a9.52 9.52 0 0 0 3-.49l-.93-1.81a7.67 7.67 0 0 1-2.07.35Zm-5.17-1.94l-.36-.38a7.4 7.4 0 0 1-2.2-5.92a7.31 7.31 0 0 1 1.54-4L17.26 9a1 1 0 0 0 .91 1h.09a1 1 0 0 0 1-.91L19.6 5a1 1 0 0 0-.29-.79a1 1 0 0 0-.79-.21l-4.09.35a1 1 0 0 0 .17 2l1.29-.11a9.45 9.45 0 0 0-2.05 5.32a9.28 9.28 0 0 0 2.67 7.26l.31.37a7.33 7.33 0 0 1 2.06 4.91a7.39 7.39 0 0 1-.26 2.47l1.8.91a8.76 8.76 0 0 0 .45-3.51a9.28 9.28 0 0 0-2.51-6.1Zm14.04.04l-1.21.09a9.65 9.65 0 0 0-7.66-15.55a9.33 9.33 0 0 0-3 .49l.91 1.8a7.67 7.67 0 0 1 9.76 7.39a7.58 7.58 0 0 1-1.65 4.72l.1-1.54a1 1 0 1 0-2-.13l-.28 4.08a1 1 0 0 0 .31.78a.94.94 0 0 0 .69.28h.1l4.08-.42a1 1 0 0 0 .9-1.1a1 1 0 0 0-1.05-.89ZM4.07 20.44h.08l4.09-.35a1 1 0 1 0-.17-2l-1.39.12a7.63 7.63 0 0 1 4.52-1.49a7.9 7.9 0 0 1 1.63.18a10.23 10.23 0 0 1-.71-2.13h-.92a9.66 9.66 0 0 0-5.9 2l.12-1.31a1 1 0 0 0-.92-1.08a1 1 0 0 0-1.08.91l-.35 4.08a1 1 0 0 0 1 1.08Zm14.35 7.79l-4.09.27a1 1 0 0 0 .13 2l1.54-.11a7.71 7.71 0 0 1-12.54-6a7.6 7.6 0 0 1 .29-2L2 21.46a9.59 9.59 0 0 0-.47 2.95A9.7 9.7 0 0 0 17.19 32l-.12 1.18a1 1 0 0 0 .89 1.1h.11a1 1 0 0 0 1-.9l.42-4.06a1 1 0 0 0-1.06-1.1Z"/></svg></>
    },
];


export default function WebSection() {
    return (
        <Section name="section-web">

        <div className="flex flex-col grow justify-center py-10 bg-gradient-to-br from-black/80 via-neutral-900/80 to-neutral-950/80">
         {/*  bg-gradient-to-br from-black/80 via-neutral-900/80 to-neutral-950/80  */}
        <div className="w-full max-w-7xl mx-auto px-5">

            <div className="text-center lg:text-left sticky top-0 ">
                <div className="font-oxanium">Next Level</div>
                <h1 className="text-eyecatcher-xl font-oxanium">Web Developement</h1>
            </div>

Hier noch einen coolen tickenden fetten gelben Eingabe Cursor hinter dem Titel

            <div className="mt-[30px] lg:mt-[40px] p-10 bg-neutral-950/90 ms-24">
                <p>Ich gestalte digitale Erlebnisse, die nicht nur visuell beeindrucken, sondern auch funktional überzeugen. Durch benutzerzentriertes Design und moderne Technologien kreiere ich intuitive, responsive Websites und Apps, die eine nahtlose und angenehme Interaktion ermöglichen. Jede digitale Lösung wird individuell angepasst, um die Bedürfnisse der Nutzer zu erfüllen und langfristige Bindungen zu schaffen. Mein Ziel ist es, Erlebnisse zu schaffen, die sowohl ästhetisch ansprechend als auch technisch ausgereift sind.</p>
            </div>

            <motion.div whileInView={"visible"} className="grid lg:grid-cols-2 gap-y-4 gap-x-10 lg:gap-x-20 mt-[30px] lg:mt-[50px]">
                {webServicesArray.map((service, index) => (
                <motion.div 
                    key={index} 
                    className="border-t border-yellow-500 flex align-middle"
                    initial={{
                        opacity: 0,
                        y: 50,
                    }} 
                    variants={{
                        visible: {
                            opacity: 1,
                            y: 0,
                            transition: {
                                duration: 1,
                                delay: 0.1 + index * 0.2
                            }
                        }
                    }}                
                    >
                    <div className="w-[46px] h-[46px] p-[5px] mr-5 bg-yellow-500 text-white text-center">
                        { service.icon }
                    </div>
                    <div className="self-start py-4">
                        <h4 className="font-bold text-xl block mb-1 text-white font-oxanium uppercase">{ service.name }</h4>
                        { service.desc }
                    </div>
                </motion.div>
                ))}
            </motion.div>

        </div>            
        </div>            
        </Section>
    )
}
