import './style.css'

import React, { useEffect, useRef, useState, Suspense } from "react";
import ReactDOM from 'react-dom/client'

import * as THREE from 'three'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { ReactLenis, useLenis } from 'lenis/react'

import Preloader from './components/Preloader'
import Header from './components/interface/Header'
import Footer from './components/interface/Footer'
import Interface from './components/Interface'

import Experience from './components/Experience'


const assetsToLoad = [
    '/assets/tree.png',
    '/assets/tree.png',
    '/assets/earth.png',
    '/assets/raven.png',
    '/assets/space.png'
];

function App() {

    const [loading, setLoading] = useState(true);

    return (
        <>

        {loading && <Preloader assetsToLoad={assetsToLoad} onLoaded={() => setLoading(false)} />}
        {!loading && (
            <>
            <div className="fixed top-0 left-0 w-full h-screen z-10">
            <Canvas shadows gl={ { 
                antialias: true, 
                toneMapping: THREE.ACESFilmicToneMapping 
            }}>
                <Suspense fallback={"Loading"}>
                    <Experience />
                </Suspense>
                {/* <OrbitControls 
                    enableZoom={false} 
                    enablePan={false} 
                    enableRotate={false} 
                    autoRotate={false} 
                    autoRotateSpeed={0.5} 
                    enableDamping={false}
                    /> */}
                
            </Canvas> 
            </div>

            <ReactLenis root options={{ 
                autoRaf: true,
                wheelMultiplier: 0.5,
                fastScrollEnd: 3000,
                easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
            }}>       
                <Interface />  
            </ReactLenis>
            <Header />
            <Footer />
            </>
      )}

        </>
    )
}

const root = document.getElementById('root');
if (!root._rootInstance) {
    root._rootInstance = ReactDOM.createRoot(root);
}

root._rootInstance.render(
    <App />
    // <React.StrictMode></React.StrictMode>
);