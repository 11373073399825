import { Stars } from "@react-three/drei";

export default function Particles(props) {
    return (
        <Stars
            radius={100}
            depth={100}
            count={10000}
            factor={4}
            saturation={0}
            fade
            speed={0.4} 
            {...props}
        />
    )
}